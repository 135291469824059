<template>
  <div class="order-contacts">
    <property-list
      :item="order"
      :properties="contacts.firstColumn"
      class="column column_layout_a"
    />

    <property-list
      :item="order"
      :properties="contacts.secondColumn"
      class="column column_layout_b"
    />
  </div>
</template>

<script>
import PropertyList from '@/components/Interface/PropertyList.vue'
import { contacts } from '@/constants/order-tabs'

export default {
  components: {
    PropertyList
  },

  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      contacts
    }
  }
}
</script>

<style lang="stylus" scoped>
  .order-contacts
    display flex

  .column
    &_layout_a
      margin-right 50px
</style>
