
export default [{
  name: 'ID заказа',
  sortable: true,
  unfoldable: true,
  key: 'id',
  type: 'id',
  styles: { minWidth: '270px' }
}, {
  name: 'Статус / Магазин, Маркетплейс',
  key: 'shop',
  type: 'shop',
  sortable: true,
  headStyle: { width: '115px' }
}, {
  name: 'Сумма заказа',
  key: 'pay_amount',
  type: 'pay_amount',
  styleType: 'numbers',
  sortable: true,
  styles: { minWidth: '120px' }
}, {
  name: 'Скидка',
  key: 'discount_amount',
  type: 'discount_amount',
  styleType: 'numbers',
  styles: { minWidth: '120px' }
}, {
  name: 'Способ доставки / время до отправки',
  key: 'logistics_type',
  type: 'logistics_type',
  styles: { textAlign: 'left' }
}]

export const productColumns = [{
  name: 'ID товара',
  key: 'product_id',
  type: 'product_id'
}, {
  name: 'Кол-во',
  styleType: 'numbers',
  key: 'product_count',
  styles: { display: 'block', minWidth: '190px' }
}, {
  name: 'Цена',
  styleType: 'numbers',
  key: 'product_unit_price_amount',
  type: 'price',
  styles: { display: 'block', minWidth: '90px' }
}, {
  name: 'Стоимость',
  styleType: 'numbers',
  key: 'total_product_amount',
  type: 'cost',
  styles: { display: 'block', minWidth: '90px' }
}, {
  name: 'Описание',
  key: 'item',
  type: 'description',
  styles: { display: 'block', textAlign: 'left', minWidth: '300px' },
  headStyle: { display: 'block', width: '100%', textAlign: 'left' }
}]
