<template>
  <div class="order-delivery">
    <property-list
      :item="order"
      :properties="delivery.firstColumn"
      class="column column_layout_a"
    >
      <template #status="{ status }">
        <badge
          v-if="status"
          :status="status"
        />
        <template v-else>
          -
        </template>
      </template>

      <template #track_url="{ track_url }">
        <a
          v-if="track_url"
          :href="track_url"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ track_url }}
        </a>
        <template v-else>
          -
        </template>
      </template>
    </property-list>

    <property-list
      :item="order"
      :properties="delivery.secondColumn"
      class="column column_layout_b"
    >
      <template #delivery_price="{ delivery_price }">
        {{ formatPrice(delivery_price) }} руб
      </template>
    </property-list>
  </div>
</template>

<script>
import PropertyList from '@/components/Interface/PropertyList.vue'
import Badge from '@/components/Common/Badge.vue'
import formatPrice from '@/utils/price-formatter'
import { delivery } from '@/constants/order-tabs'

export default {
  components: {
    PropertyList,
    Badge
  },

  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      delivery
    }
  },

  methods: {
    formatPrice
  }
}
</script>

<style lang="stylus" scoped>
  .order-delivery
    display flex

  .column
    &_layout_a
      margin-right 120px
</style>
