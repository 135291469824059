<template>
  <div class="order-payment">
    <property-list
      :properties="payment.firstColumn"
      :item="order"
      class="column column_layout_a"
    >
      <template #status="{ status }">
        <badge :status="status" />
      </template>

      <template #gmt_pay_time="{ gmt_pay_time }">
        {{ formatDate(gmt_pay_time) }}
      </template>
    </property-list>

    <property-list
      :properties="payment.secondColumn"
      :item="order"
      class="column column_layout_b"
    >
      <template #total="{ total }">
        {{ formatPrice(total) }}
      </template>
    </property-list>
  </div>
</template>

<script>
import PropertyList from '@/components/Interface/PropertyList.vue'
import Badge from '@/components/Common/Badge.vue'
import { payment } from '@/constants/order-tabs'
import formatDate from '@/utils/date-formatter'
import formatPrice from '@/utils/price-formatter'

export default {
  components: {
    PropertyList,
    Badge
  },

  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      payment
    }
  },

  methods: {
    formatDate,
    formatPrice
  }
}
</script>

<style lang="stylus" scoped>
  .order-payment
    display flex

  .column
    &_layout_a
      margin-right 120px
</style>
