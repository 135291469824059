export default [{
  name: 'ID товара',
  sortable: 'true',
  key: 'id',
  type: 'id'
}, {
  name: 'Наименование',
  sortable: 'true',
  key: 'name',
  type: 'name'
}, {
  name: 'Цена РРЦ',
  sortable: 'true',
  key: 'product_unit_price_amount',
  type: 'price',
  styleType: 'numbers'
}, {
  name: 'Кол-во',
  sortable: 'true',
  key: 'product_count',
  type: 'product_count',
  styleType: 'numbers'
}, {
  name: 'Сумма РРЦ',
  sortable: 'true',
  key: 'total_product_amount',
  type: 'price',
  styleType: 'numbers'
}, {
  name: 'Скидка',
  sortable: 'true',
  key: 'logistics_amount',
  styleType: 'numbers',
  type: 'price'
}, {
  name: 'Стоимость доставки',
  sortable: 'true',
  type: 'price',
  key: 'logistics_amount',
  styleType: 'numbers'
}]
