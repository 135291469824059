<template>
  <div class="order">
    <header class="order__header">
      <back-button
        :to="{ name: 'orders' }"
        class="order__back"
        icon="back"
      >
        <heading class="order__heading">
          Заказ: {{ order.order_id }}
        </heading>
      </back-button>

      <refresh-button @click="getOrderInfo" />
    </header>

    <div
      v-if="isLoading"
      class="order__loading-preview"
    >
      <loader class="order__loader" />
    </div>

    <template v-else>
      <order-preview-tabs
        :order="order"
        :order-statuses="orderStatuses"
        class="order__tabs"
      />

      <order-products :order="order" />
    </template>
  </div>
</template>

<script>
import Heading from '@/components/Common/Heading.vue'
import BackButton from '@/components/Interface/BackButton.vue'
import RefreshButton from '@/components/Interface/RefreshButton.vue'
import OrderPreviewTabs from '@/components/Orders/PreviewTabs/Index.vue'
import Loader from '@/components/Common/Loader.vue'
import OrderProducts from '@/components/Orders/OrderProducts.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapActions, mapState } = createNamespacedHelpers('orders')

export default {
  components: {
    Heading,
    BackButton,
    RefreshButton,
    OrderPreviewTabs,
    Loader,
    OrderProducts
  },

  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },

  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    ...mapState(['order', 'orderStatuses'])
  },

  created () {
    this.getOrderInfo()
    this.getOrderStatuses()
  },

  methods: {
    ...mapActions(['getOrder', 'getOrderStatuses']),

    async getOrderInfo () {
      try {
        this.isLoading = true
        await this.getOrder(this.id)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .order
    &__header
      display flex
      margin-bottom 23px

    &__heading
      margin 0 20px 0 25px

    &__back
      display flex
      align-items center

    &__loading-preview
      position relative
      height 385px
      border-radius 8px
      box-shadow 0px 6px 16px rgba(5, 2, 55, 0.04)
      background-color #fdfdfd

    &__loader
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)

    &__tabs
      margin-bottom 30px
</style>
