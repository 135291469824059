<template>
  <div class="orders-tabs">
    <div
      v-if="isLoading"
      class="orders-tabs__loader"
    >
      <loader />
    </div>

    <template v-else>
      <tabs
        type="marketplace"
        :tabs="ordersTabs"
        :active="currentTab.path"
        class="orders-tabs__tabs"
        @click="onTabChange"
      />

      <filters
        :status="orderStatus"
        :shop="shop"
        :marketplace="currentTab"
        :created-period="createdPeriod"
        :payment-period="paymentPeriod"
        :send-period="sendPeriod"
        :dispute-period="disputePeriod"
        @search="term => filters.order_id = term"
        @change:shop="onShopChange"
        @change:shop-discount="checked => filters.shop_discount = checked"
        @change:marketplace-discount="checked => filters.marketplace_discount = checked"
        @change:created-period="onChangePeriod($event, 'createdPeriod', 'created')"
        @change:payment-period="onChangePeriod($event, 'paymentPeriod', 'paid')"
        @change:send-period="onChangePeriod($event, 'sendPeriod', 'sent')"
        @change:dispute-period="onChangePeriod($event, 'disputePeriod', 'issue')"
        @change:order-status="onStatusChange"
        @reset="resetFilters"
      />

      <div
        v-if="isGridLoading"
        class="orders-tabs__loader"
      >
        <loader />
      </div>

      <template v-else>
        <order-list
          :orders="orders"
          :download-pending="downloadPending"
          @download-click="$modal.show('confirm')"
        />

        <pagination
          v-if="orders.length"
          class="orders-tabs__pagination"
          :data="pagination"
          :current-page="currentPage"
          @change="onPaginationChange"
        />
      </template>
    </template>

    <confirm-modal
      @decline="$modal.hide('confirm')"
      @confirm="onConfirmClick"
    >
      <template #text>
        Внимание! <br>
        <span class="orders-tabs__confirm-text">
          Выгрузка может занять продолжительное время.
          Время зависит от количества выгружаемых заказов.
        </span>
      </template>

      <template #confirm>
        Начать выгрузку
      </template>

      <template #decline>
        Отменить
      </template>
    </confirm-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Tabs from '@/components/Interface/Tabs.vue'
import Filters from '@/components/Orders/Filters.vue'
import OrderList from '@/components/Orders/OrderList.vue'
import Loader from '@/components/Common/Loader.vue'
import Pagination from '@/components/Interface/Pagination.vue'
import PaginationMixin from '@/mixins/pagination'
import ConfirmModal from '@/components/Common/ConfirmModal.vue'
import { defaultFilterOption } from '@/constants/dashboards-filters'
import _debounce from 'lodash.debounce'

const { mapActions, mapState } = createNamespacedHelpers('orders')
const { mapActions: mapShopsActions } = createNamespacedHelpers('marketplaces')

const emptyOption = {
  start: null,
  end: null
}

let debouncedFetch

export default {
  mixins: [PaginationMixin],

  components: {
    Tabs,
    Filters,
    OrderList,
    Loader,
    Pagination,
    ConfirmModal
  },

  data () {
    return {
      isLoading: false,
      isGridLoading: false,
      downloadPending: false,
      orderStatus: defaultFilterOption,
      shop: defaultFilterOption,
      createdPeriod: emptyOption,
      paymentPeriod: emptyOption,
      sendPeriod: emptyOption,
      disputePeriod: emptyOption,
      filters: {
        order_id: null,
        shop_id: null,
        status: null,
        shop_discount: null,
        marketplace_discount: null,
        created_from: null,
        created_to: null,
        paid_from: null,
        paid_to: null,
        sent_from: null,
        sent_to: null,
        issue_from: null,
        issue_to: null,
        marketplace: null
      }
    }
  },

  watch: {
    computedFilters: {
      handler () {
        if (this.currentPage === 1) {
          debouncedFetch()
          return
        }
        this.currentPage = 1
      },
      deep: true
    }
  },

  computed: {
    ...mapState(['orders', 'ordersTabs']),

    activeTab () {
      return +this.$route.query?.marketplace || 'ALL'
    },

    currentTab () {
      if (!this.ordersTabs.length) return {}

      return this.ordersTabs.find(t => t.path === this.activeTab)
    },

    computedFilters () {
      return Object.keys(this.filters).reduce((filters, filter) => {
        filters[filter] = this.filters[filter] !== 'ALL' ? this.filters[filter] : null
        return filters
      }, {})
    }
  },

  async created () {
    try {
      debouncedFetch = _debounce(this.fetchData, 300)

      this.isLoading = true
      await this.getMatketplacesList()
      await this.fetchData()

      if (!this.orders.length) {
        this.getShopList()
      }
    } finally {
      this.isLoading = false
    }
  },

  methods: {
    ...mapActions([
      'getOrders',
      'getMatketplacesList',
      'downloadOrderList',
      'getOrderStatuses'
    ]),
    ...mapShopsActions(['getShopList']),

    onChangePeriod (period, prop, filter) {
      this[prop] = period
      const { start, end } = period

      this.filters[`${filter}_from`] = start
      this.filters[`${filter}_to`] = end
    },

    onTabChange (marketplace) {
      this.filters.marketplace = marketplace
      this.resetFilters()
    },

    onShopChange (option) {
      this.filters.shop_id = option.id
      this.shop = option
    },

    onStatusChange (option) {
      this.filters.status = option.id
      this.orderStatus = option
    },

    resetFilters () {
      Object.keys(this.filters).forEach(filter => {
        if (filter === 'marketplace') return
        this.filters[filter] = null
      })
      this.shop = this.orderStatus = defaultFilterOption
      this.createdPeriod = this.paymentPeriod = this.sendPeriod = this.disputePeriod = emptyOption
    },

    async fetchData (params = {}) {
      try {
        this.isGridLoading = true
        const marketplace = this.currentTab.id !== 'ALL' ? this.currentTab.id : null

        params = {
          ...params,
          ...this.computedFilters,
          marketplace,
          page: this.currentPage,
          limit: this.pagination.limit
        }

        await this.getOrderStatuses({
          marketplace: this.currentTab.code
        })

        this.pagination = { ...this.pagination, ...await this.getOrders(params) }
      } finally {
        this.isGridLoading = false
      }
    },

    async onConfirmClick () {
      try {
        this.$modal.hide('confirm')
        this.downloadPending = true

        await this.downloadOrderList(this.computedFilters)
      } finally {
        this.downloadPending = false
      }
    },

    onPaginationChange ({ page, limit, offset }) {
      this.currentPage = page

      this.pagination.limit = limit

      this.fetchData({
        page,
        limit,
        offset
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .orders-tabs
    &__loader
      padding 40px
      text-align center

    &__pagination
      margin-top 30px

    &__confirm-text
      font-family "proxima_nova_regular"
      font-weight normal
      font-size 16px
</style>
