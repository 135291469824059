<template>
  <ul class="property-list">
    <li
      v-for="(property, idx) in properties"
      :key="`${property.key}-${idx}`"
      class="property-list__item"
    >
      <dl class="property-list__property">
        <dt
          class="property-list__property_name"
          :style="property.keyStyles"
        >
          {{ property.name }}
        </dt>

        <dd
          v-if="property.type"
          class="property-list__property_value"
          :style="property.styles"
        >
          <slot
            :name="property.type"
            :[property.type]="getValueByKey(item, property.key)"
            :item="item"
            :property="property"
            :property-index="idx"
          />
        </dd>

        <dd
          v-else
          class="property-list__property_value"
          :style="property.styles"
        >
          {{ getValueByKey(item, property.key) || '-' }}
        </dd>
      </dl>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    properties: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    // Can read nested property keys.
    // Example: 'key.nestedKey.nestedKey...'
    getValueByKey (item, key) {
      const keys = Array.isArray(key) ? key : key.split('.')
      if (keys.length > 1) {
        return this.getValueByKey(item[keys[0]], keys.slice(1, keys.length))
      }
      return item[keys[0]]
    }
  }
}
</script>

<style lang="stylus" scoped>
  .property-list
    display flex
    flex-direction column
    list-style none

    &__property
      display flex
      margin-bottom 20px
      font-size 14px

      &_name
        min-width 190px
        margin-right 20px
        color #9b9ba3

      &_value
        color #3c3c47
</style>
