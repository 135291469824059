<template>
  <div class="orders">
    <orders-description class="orders__description" />

    <orders-tabs class="orders__tabs" />
  </div>
</template>

<script>
import OrdersTabs from '@/components/Orders/OrdersTabs.vue'
import OrdersDescription from '@/components/Orders/Description.vue'

export default {
  components: {
    OrdersTabs,
    OrdersDescription
  }
}
</script>

<style lang="stylus" scoped>
  .orders
    &__description
      margin-bottom 30px

    &__tabs
      padding 35px 30px
      border-radius 8px
      background-color #fdfdfd
      box-shadow: 0px 6px 16px rgba(5, 2, 55, 0.04)
</style>
