var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-tabs-info"},[_c('property-list',{staticClass:"column column_layout_a",attrs:{"properties":_vm.firstColumn,"item":_vm.order},scopedSlots:_vm._u([{key:"shop",fn:function(ref){
var item = ref.item;
var shop = ref.shop;
return [_c('marketplace',{attrs:{"full":"","name":item.marketplace.code,"shop":shop.name}})]}},{key:"status",fn:function(ref){
var status = ref.status;
return [(status)?_c('badge',{attrs:{"status":status}}):[_vm._v(" - ")]]}},{key:"frozen_status",fn:function(ref){
var frozen_status = ref.frozen_status;
return [(frozen_status)?_c('badge',{attrs:{"status":frozen_status}}):_c('badge',{attrs:{"status":"not_frozen"}})]}},{key:"date",fn:function(ref){
var date = ref.date;
return [_vm._v(" "+_vm._s(date || '-')+" ")]}}])}),_c('property-list',{staticClass:"column column_layout_b",attrs:{"properties":_vm.secondColumn,"item":_vm.order},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var status = ref.status;
return [_c('badge',{attrs:{"status":status,"status-list":_vm.statuses}})]}},{key:"end_reason",fn:function(ref){
var end_reason = ref.end_reason;
return [(end_reason)?_c('strong',[_vm._v(_vm._s(end_reason))]):[_vm._v(" - ")]]}},{key:"date",fn:function(ref){
var date = ref.date;
return [_vm._v(" "+_vm._s(_vm.formatDate(date) || '-')+" ")]}},{key:"receipts",fn:function(ref){
var receipts = ref.receipts;
return [(receipts && receipts.length)?_c('ul',_vm._l((receipts),function(check){return _c('li',{key:check.receipt_datetime},[_vm._v(" "+_vm._s(receipts[0].receipt_datetime)+" "),(receipts[0].ofd_receipt_url)?_c('a',{staticClass:"link",attrs:{"href":receipts[0].ofd_receipt_url}},[_vm._v(" Просмотреть ")]):_vm._e()])}),0):[_vm._v(" - ")]]}},{key:"shipment_date",fn:function(ref){
var shipment_date = ref.shipment_date;
return [(shipment_date)?_c('div',{staticClass:"order-tabs-info__shipment"},[_vm._v(" До отправки: "),_c('strong',[_vm._v(_vm._s(_vm.getShipmentDiff(shipment_date)))])]):[_vm._v(" - ")]]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }