<template>
  <div class="order-tabs-info">
    <property-list
      :properties="firstColumn"
      :item="order"
      class="column column_layout_a"
    >
      <template #shop="{ item, shop }">
        <marketplace
          full
          :name="item.marketplace.code"
          :shop="shop.name"
        />
      </template>

      <template #status="{ status }">
        <badge
          v-if="status"
          :status="status"
        />
        <template v-else>
          -
        </template>
      </template>

      <template #frozen_status="{ frozen_status }">
        <badge
          v-if="frozen_status"
          :status="frozen_status"
        />
        <badge
          v-else
          status="not_frozen"
        />
      </template>

      <template #date="{ date }">
        {{ date || '-' }}
      </template>
    </property-list>

    <property-list
      :properties="secondColumn"
      :item="order"
      class="column column_layout_b"
    >
      <template #status="{ status }">
        <badge
          :status="status"
          :status-list="statuses"
        />
      </template>

      <template #end_reason="{ end_reason }">
        <strong v-if="end_reason">{{ end_reason }}</strong>
        <template v-else>
          -
        </template>
      </template>

      <template #date="{ date }">
        {{ formatDate(date) || '-' }}
      </template>

      <template #receipts="{ receipts }">
        <ul v-if="receipts && receipts.length">
          <li
            v-for="check in receipts"
            :key="check.receipt_datetime"
          >
            {{ receipts[0].receipt_datetime }}
            <a
              v-if="receipts[0].ofd_receipt_url"
              :href="receipts[0].ofd_receipt_url"
              class="link"
            >
              Просмотреть
            </a>
          </li>
        </ul>
        <template v-else>
          -
        </template>
      </template>

      <template #shipment_date="{ shipment_date }">
        <div
          v-if="shipment_date"
          class="order-tabs-info__shipment"
        >
          До отправки: <strong>{{ getShipmentDiff(shipment_date) }}</strong>
        </div>
        <template v-else>
          -
        </template>
      </template>
    </property-list>
  </div>
</template>

<script>
import PropertyList from '@/components/Interface/PropertyList.vue'
import Marketplace from '@/components/Common/Marketplace.vue'
import Badge from '@/components/Common/Badge.vue'
import { firstColumn, secondColumn } from '@/constants/order-tabs'
import formatDate, { getDiff } from '@/utils/date-formatter'

export default {
  components: {
    PropertyList,
    Marketplace,
    Badge
  },

  props: {
    order: {
      type: Object,
      default: () => ({})
    },
    statuses: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      firstColumn,
      secondColumn
    }
  },

  methods: {
    formatDate,

    getShipmentDiff (shipDate) {
      const diff = getDiff(new Date(), shipDate)
      const days = Math.floor(diff.asDays())
      return `${days} дн ${diff.hours()} ч ${diff.minutes()} мин`
    }
  }
}
</script>

<style lang="stylus" scoped>
  .order-tabs-info
    display flex

    &__shipment
      padding 2px 8px
      border-radius 10px
      background-color #fcebeb

  .column
    &_layout_a
      margin-right 85px
</style>
