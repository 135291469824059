<template>
  <div class="order-products">
    <grid
      auto
      default-sort-by="id"
      :cells="order.order_items"
      :columns="columns"
    >
      <template #id="{ row }">
        <div class="order-products__meta">
          <img
            v-if="row.item && row.item.image_url"
            :src="row.item.image_url"
            class="order-products__image"
          >
          <svg-icon
            v-else
            name="no-image"
            class="order-products__image"
          />
          <product-meta
            :product="{id: row.id, ...row.item}"
          />
        </div>
      </template>

      <template #name="{ row }">
        <tooltip
          v-if="row.item && row.item.name"
          no-arrow
        >
          <span class="order-products__name">
            {{ row.item.name }}
          </span>
          <template #popover>
            {{ row.item.name }}
          </template>
        </tooltip>
      </template>

      <template #price="{ price }">
        <template v-if="price">
          {{ formatPrice(price) }} руб.
        </template>
      </template>

      <template #product_count="{ product_count }">
        {{ product_count }} шт
      </template>
    </grid>
  </div>
</template>

<script>
import Grid from '@/components/Interface/Grid.vue'
import ProductMeta from '@/components/Common/ProductMeta.vue'
import Tooltip from '@/components/Common/Tooltip.vue'
import columns from '@/constants/order'
import formatPrice from '@/utils/price-formatter'

export default {
  components: {
    Grid,
    ProductMeta,
    Tooltip
  },

  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      columns
    }
  },

  methods: {
    formatPrice
  }
}
</script>

<style lang="stylus" scoped>
  .order-products
    padding 30px
    border-radius 8px
    box-shadow 0px 6px 16px rgba(5, 2, 55, 0.04)
    background-color #fdfdfd

    &__meta
      display flex

    &__image
      max-width 70px
      max-height 70px
      margin-right 10px

    &__name
      display block
      max-width 250px
      white-space nowrap
      overflow hidden
      text-overflow ellipsis
</style>
