<template>
  <div class="order-preview-tabs">
    <tabs
      :tabs="tabs"
      :active="currentTab.path"
      class="order-preview-tabs__tabs"
      @click="tab => activeTab = tab"
    />

    <component
      :is="currentTab.path"
      :order="order"
      :statuses="computedStatuses"
    />
  </div>
</template>

<script>
import Tabs from '@/components/Interface/Tabs.vue'
import Info from '@/components/Orders/PreviewTabs/Info.vue'
import Delivery from '@/components/Orders/PreviewTabs/Delivery.vue'
import Contacts from '@/components/Orders/PreviewTabs/Contacts.vue'
import Payment from '@/components/Orders/PreviewTabs/Payment.vue'

export default {
  components: {
    Tabs,
    Info,
    Delivery,
    Contacts,
    Payment
  },

  props: {
    order: {
      type: Object,
      default: () => ({})
    },
    orderStatuses: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      activeTab: 'Info',
      tabs: [{
        title: 'Общая информация',
        path: 'Info'
      }, {
        title: 'Доставка',
        path: 'Delivery'
      }, {
        title: 'Контактная информация',
        path: 'Contacts'
      }, {
        title: 'Информация  об оплате',
        path: 'Payment'
      }]
    }
  },

  computed: {
    currentTab () {
      return this.tabs.find(t => t.path === this.activeTab)
    },

    computedStatuses () {
      return this.orderStatuses.reduce((list, status) => {
        list[status.value] = status.name
        return list
      }, {})
    }
  }
}
</script>

<style lang="stylus" scoped>
  .order-preview-tabs
    padding 30px
    border-radius 8px
    box-shadow 0px 6px 16px rgba(5, 2, 55, 0.04)
    background-color #fdfdfd

    &__tabs
      margin-bottom 30px
</style>
