<template>
  <btn
    class="unfold-button"
    @click="$emit('click')"
  >
    <slot />
    <svg-icon
      name="chevron-down"
      class="unfold-button__icon"
      :class="{'active': active, 'margin': $slots.default}"
      :style="{stroke: active ? activeColor : color}"
    />
  </btn>
</template>

<script>
import Btn from '@/components/Interface/Button.vue'

export default {
  components: {
    Btn
  },
  props: {
    active: Boolean,
    color: {
      type: String,
      default: '#3c3c47'
    },
    activeColor: {
      type: String,
      default: '#3c3c47'
    }
  }
}
</script>

<style lang="stylus" scoped>
  .unfold-button
    &__icon
      width 16px
      height 16px
      transition transform .4s

      &.active
        transform rotate(-180deg)

      &.margin
        margin-left 6px
</style>
