<template>
  <div class="orders-filters">
    <ul class="orders-filters__list">
      <li class="orders-filters__item">
        <field-composition
          class="field-composition_content_filter"
          small
          name="search"
          label="Поиск"
        >
          <input-field
            v-model="search"
            placeholder="ID заказа"
            class="form-field-box_content_filter orders-filters__search"
            @input="$emit('search', $event || null)"
          >
            <svg-icon
              name="search"
              class="orders-filters__search-icon"
            />
          </input-field>
        </field-composition>
      </li>

      <li class="orders-filters__item">
        <shop-filter
          secondary
          border
          :shop="shop"
          :marketplace="marketplace"
          @change:shop="$emit('change:shop', $event)"
        />
      </li>

      <li class="orders-filters__item">
        <field-composition
          small
          name="status"
          label="Статус"
        >
          <select-field
            secondary
            border
            track-by="id"
            label="label"
            placeholder="Все статусы"
            :value="status"
            :options="computedStatuses"
            :limit="1"
            :limit-text="count => `и ещё ${count}`"
            :searchable="false"
            :allow-empty="false"
            @select="$emit('change:order-status', $event)"
          />
        </field-composition>
      </li>

      <li
        v-if="false"
        class="orders-filters__item orders-filters__item_content_checkboxes"
      >
        <checkbox
          v-model="shopDiscount"
          class="orders-filters__checkbox"
          @change="$emit('change:shop-discount', shopDiscount)"
        >
          Скидка магазина
        </checkbox>
        <checkbox
          v-model="marketplaceDiscount"
          class="orders-filters__checkbox"
          @change="$emit('change:marketplace-discount', marketplaceDiscount)"
        >
          Скидка маркетплейса
        </checkbox>
      </li>
    </ul>

    <ul
      class="orders-filters__list"
    >
      <li class="orders-filters__item">
        <field-composition
          small
          name="createdPeriod"
          label="Дата создания"
        >
          <datepicker
            border
            has-reset-button
            :max-date="filtersMaxDate"
            :default-period="currentCreatedPeriod"
            :value="createdPeriod"
            @change="value => $emit('change:created-period', value)"
            @change-default-period="p => currentCreatedPeriod = p"
          />
        </field-composition>
      </li>

      <li class="orders-filters__item">
        <field-composition
          small
          name="paymentPeriod"
          label="Дата оплаты"
        >
          <datepicker
            border
            has-reset-button
            :max-date="filtersMaxDate"
            :default-period="currentPaymentPeriod"
            :value="paymentPeriod"
            @change="value => $emit('change:payment-period', value)"
            @change-default-period="p => currentPaymentPeriod = p"
          />
        </field-composition>
      </li>

      <li class="orders-filters__item">
        <field-composition
          small
          name="sendPeriod"
          label="Дата отправки"
        >
          <datepicker
            border
            has-reset-button
            :max-date="filtersMaxDate"
            :default-period="currentSendPeriod"
            :value="sendPeriod"
            @change="value => $emit('change:send-period', value)"
            @change-default-period="p => currentSendPeriod = p"
          />
        </field-composition>
      </li>

      <li class="orders-filters__item">
        <field-composition
          small
          name="disputePeriod"
          label="Дата спора"
        >
          <datepicker
            border
            has-reset-button
            :max-date="filtersMaxDate"
            :default-period="currentDisputePeriod"
            :value="disputePeriod"
            @change="value => $emit('change:dispute-period', value)"
            @change-default-period="p => currentDisputePeriod = p"
          />
        </field-composition>
      </li>

      <li class="orders-filters__item orders-filters__item_content_reset">
        <reset-button
          @click="onReset"
        >
          Очистить фильтры
        </reset-button>
      </li>
    </ul>
  </div>
</template>

<script>
import FieldComposition from '@/components/Interface/FieldComposition.vue'
import InputField from '@/components/Interface/Input.vue'
import ShopFilter from '@/components/Interface/ShopFilter.vue'
import SelectField from '@/components/Interface/Select.vue'
import Checkbox from '@/components/Interface/Checkbox.vue'
import Datepicker from '@/components/Interface/Datepicker.vue'
import ResetButton from '@/components/Interface/ResetButton.vue'
import CalendarPeriods from '@/constants/calendar-periods'
import { createNamespacedHelpers } from 'vuex'

const { mapActions, mapState } = createNamespacedHelpers('orders')

const today = new Date()

export default {
  components: {
    FieldComposition,
    InputField,
    ShopFilter,
    SelectField,
    Checkbox,
    Datepicker,
    ResetButton
  },

  props: {
    status: {
      type: [String, Object],
      default: ''
    },
    shop: {
      type: [String, Object],
      default: ''
    },
    marketplace: {
      type: Object,
      default: null
    },
    createdPeriod: {
      type: Object,
      default: () => ({
        start: today,
        end: today
      })
    },
    paymentPeriod: {
      type: Object,
      default: () => ({
        start: today,
        end: today
      })
    },
    sendPeriod: {
      type: Object,
      default: () => ({
        start: today,
        end: today
      })
    },
    disputePeriod: {
      type: Object,
      default: () => ({
        start: today,
        end: today
      })
    }
  },

  data () {
    return {
      search: null,
      shopDiscount: false,
      marketplaceDiscount: false,
      currentCreatedPeriod: CalendarPeriods.TODAY,
      currentPaymentPeriod: CalendarPeriods.TODAY,
      currentSendPeriod: CalendarPeriods.TODAY,
      currentDisputePeriod: CalendarPeriods.TODAY
    }
  },

  computed: {
    ...mapState(['orderStatuses']),

    computedStatuses () {
      const statusOptions = this.orderStatuses.map(s => ({ label: s.name, id: s.value }))
      statusOptions.unshift({ label: 'Все', id: 'ALL' })

      return statusOptions
    },

    filtersMaxDate () {
      return today
    }
  },

  methods: {
    ...mapActions(['getOrderStatuses']),

    onReset () {
      this.search = null
      this.$emit('reset')
    }
  }
}
</script>

<style lang="stylus" scoped>
  .orders-filters
    padding 10px 0 40px

    &__list
      display flex
      padding-top 10px
      margin-left -10px

    &__item
      flex-basis 210px
      margin-left 10px
      list-style none

      &_content_checkboxes
        display flex
        align-items center
        justify-content space-between
        flex-basis 320px
        margin-left 20px

      &_content_reset
        display flex
        align-items flex-end

    &__search
      position relative

      >>>input
        padding-left 25px

    &__search-icon
      position absolute
      left 8px
      top 10px
      width 16px
      height 16px

    &__checkbox
      transform translateY(50%)
</style>
