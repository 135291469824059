var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-list"},[_c('div',{staticClass:"order-list__heading"},[_c('div',{staticClass:"order-list__heading-info"},[(_vm.orders.length)?_c('span',{staticClass:"order-list__count"},[_vm._v(" Найдено заказов: "),_c('strong',[_vm._v(_vm._s(_vm.ordersCount))])]):_vm._e(),(_vm.orders.length)?_c('checkbox',{staticClass:"order-list__checkbox",attrs:{"rounded":""},model:{value:(_vm.unfoldAll),callback:function ($$v) {_vm.unfoldAll=$$v},expression:"unfoldAll"}},[_vm._v(" Раскрыть все заказы ")]):_vm._e()],1),_c('btn',{staticClass:"order-list__export",attrs:{"primary":"","icon":"download","is-load":_vm.downloadPending},on:{"click":function($event){return _vm.$emit('download-click')}}},[_vm._v(" Выгрузить в Excel ")])],1),_c('grid',{attrs:{"auto":"","unfoldable":"","selectable":!!_vm.orders.length,"cells":_vm.computedOrders,"columns":_vm.columns},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var row = ref.row;
return [_c('unfold-button',{attrs:{"active":row.isUnfold},on:{"click":function($event){return _vm.$set(row, 'isUnfold', !row.isUnfold)}}},[(row.order_items.length && row.order_items[0].item && row.order_items[0].item.image_url)?_c('img',{staticClass:"order-list__image",attrs:{"src":row.order_items[0].item.image_url,"alt":"order image"}}):_c('svg-icon',{staticClass:"order-list__image",attrs:{"name":"no-image"}}),_c('product-meta',{staticClass:"order-list__meta",attrs:{"is-order":"","order":Object.assign({}, row, {id: row.order_id}),"path-name":"order","path-params":{id: row.id}}})],1)]}},{key:"unfold",fn:function(ref){
var row = ref.row;
return [_c('grid',{staticClass:"order-list__product-grid",attrs:{"auto":"","bordered":"","cells":row.order_items,"columns":_vm.productColumns},scopedSlots:_vm._u([{key:"product_id",fn:function(ref){
var product = ref.row;
return [_c('div',{staticClass:"order-list__product-meta"},[(product.item && product.item.image_url)?_c('img',{staticClass:"order-list__image",attrs:{"src":product.item.image_url,"alt":"order image"}}):_c('svg-icon',{staticClass:"order-list__image",attrs:{"name":"no-image"}}),_c('product-meta',{staticClass:"order-list__meta",attrs:{"product":Object.assign({}, product, {barcode: product.item && product.item.barcode})}})],1)]}},{key:"price",fn:function(ref){
var price = ref.price;
return [_vm._v(" "+_vm._s(_vm.formatPrice(price))+" "),_c('br'),_vm._v(" руб ")]}},{key:"cost",fn:function(ref){
var cost = ref.cost;
return [_vm._v(" "+_vm._s(_vm.formatPrice(cost))+" "),_c('br'),_vm._v(" руб ")]}},{key:"description",fn:function(ref){
var description = ref.description;
return [_vm._v(" "+_vm._s(description ? description.name : '')+" ")]}}],null,true)})]}},{key:"shop",fn:function(ref){
var shop = ref.shop;
var row = ref.row;
return [_c('badge',{staticClass:"order-list__badge",attrs:{"status":row.order_status,"status-list":_vm.computedStatuses}}),_c('marketplace',{staticClass:"order-list__marketplace",attrs:{"full":"","name":row.marketplace.code,"shop":shop.name}})]}},{key:"pay_amount",fn:function(ref){
var pay_amount = ref.pay_amount;
return [_c('strong',[_vm._v(_vm._s(_vm.formatPrice(pay_amount))+" руб")])]}},{key:"discount_amount",fn:function(ref){
var discount_amount = ref.discount_amount;
return [_vm._v(" "+_vm._s(_vm.formatPrice(discount_amount))+" руб ")]}},{key:"logistics_type",fn:function(ref){
var logistics_type = ref.logistics_type;
var row = ref.row;
return [(row.shipment_date)?[_vm._v(" До отправки: "),_c('br'),_c('span',{staticClass:"order-list__delivery"},[_c('strong',[_vm._v(_vm._s(_vm.getShipmentDiff(row.shipment_date)))]),_c('br')])]:_vm._e(),_c('p',{staticClass:"order-list__delivery-method"},[_vm._v(" "+_vm._s(logistics_type)+" ")])]}}])}),(!_vm.orders.length)?_c('no-data',{staticClass:"order-list__no-data",attrs:{"action":_vm.shops.length ? null : 'Добавить магазин'},on:{"click":_vm.goToShopAdd},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('svg-icon',{staticClass:"order-list__no-data-icon",attrs:{"name":"empty-table"}})]},proxy:true}],null,false,530284913)},[_vm._v(" Информация в таблице отсутствует ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }