<template>
  <btn
    type="button"
    icon="refresh"
    class="refresh-btn"
    @click="$emit('click')"
  >
    <slot>
      Обновить
    </slot>
  </btn>
</template>

<script>
import Btn from '@/components/Interface/Button.vue'

export default {
  components: {
    Btn
  }
}
</script>

<style lang="stylus" scoped>
  .refresh-btn
    color #5b40ff
    stroke #5b40ff
    font-weight 600

    >>>.svg-icon
      transition transform .4s ease-in-out

    &:hover
      color #4430bf
      stroke #4430bf

      >>>.svg-icon
        transform rotate(360deg)
</style>
