export const firstColumn = [{
  name: 'Магазин',
  key: 'shop',
  type: 'shop'
}, {
  name: 'ID коннектора',
  key: 'id'
}, {
  name: 'ID маркетплейса ',
  key: 'marketplace.id'
}, {
  name: 'Статус заморозки',
  key: 'frozen_status',
  type: 'frozen_status',
  styles: { minWidth: '150px' }
}, {
  name: 'Статус спора',
  key: 'issue_status',
  type: 'status',
  styles: { minWidth: '150px' }
}, {
  name: 'Дата спора',
  key: 'gmt_issue_time',
  type: 'date'
}]

export const secondColumn = [{
  name: 'Статус заказа',
  key: 'order_status',
  type: 'status',
  styles: { minWidth: '150px' }
}, {
  name: 'Причина окончания заказа',
  key: 'end_reason',
  type: 'end_reason'
}, {
  name: 'Дата создания',
  key: 'gmt_create',
  type: 'date'
}, {
  name: 'Дата последнего изменения',
  key: 'gmt_update',
  type: 'date'
}, {
  name: 'Статус оплаты',
  key: 'fund_status',
  type: 'status',
  styles: { minWidth: '150px' }
}, {
  name: 'Чек оплаты:',
  key: 'receipts',
  type: 'receipts'
}, {
  name: 'Осталось до отправки',
  key: 'shipment_date',
  type: 'shipment_date'
}]

export const delivery = {
  firstColumn: [{
    name: 'Статус логистики',
    key: 'logistics_status',
    type: 'status'
  }, {
    name: 'Статус доставки',
    key: 'delivery_status',
    type: 'status'
  }, {
    name: 'Трэк номер',
    key: 'track_number'
  }, {
    name: 'Трэк линк',
    key: 'track_url',
    type: 'track_url'
  }],
  secondColumn: [{
    name: 'Способ доставки',
    key: 'delivery_method',
    styles: { minWidth: '150px' }
  }, {
    name: 'Стоимость доставки:',
    key: 'delivery_price',
    type: 'delivery_price'
  }]
}

export const contacts = {
  firstColumn: [{
    name: 'Идентификатор покупателя',
    key: 'buyer_login_id'
  }, {
    name: 'Контактное лицо Ali',
    key: 'contact_person',
    styles: { fontFamily: 'proxima_nova_bold' }
  }, {
    name: 'Логин покупателя',
    key: 'buyer_signer_fullname',
    styles: { fontFamily: 'proxima_nova_bold' }
  }],
  secondColumn: [{
    name: 'Полный адрес',
    key: 'address_full',
    styles: {
      display: 'block',
      maxWidth: '384px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontFamily: 'proxima_nova_bold'
    }
  }, {
    name: 'Страна',
    key: 'country'
  }, {
    name: 'Почтовый индекс',
    key: 'zip'
  }, {
    name: 'Регион/облатсь',
    key: 'province'
  }, {
    name: 'Город',
    key: 'city'
  }, {
    name: 'Адрес/улица',
    key: 'street'
  }, {
    name: 'Адрес, вторая строка/ дом',
    key: 'address2'
  }]
}

export const payment = {
  firstColumn: [{
    name: 'Статус оплаты',
    key: 'fund_status',
    type: 'status'
  }, {
    name: 'Дата оплаты',
    key: 'gmt_pay_time',
    type: 'gmt_pay_time'
  }],
  secondColumn: [{
    name: 'Сумма оплаты',
    key: 'pay_amount',
    type: 'total',
    styles: { fontFamily: 'proxima_nova_bold' }
  }, {
    name: 'Валюта',
    key: 'pay_currency'
  }]
}
