var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-products"},[_c('grid',{attrs:{"auto":"","default-sort-by":"id","cells":_vm.order.order_items,"columns":_vm.columns},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"order-products__meta"},[(row.item && row.item.image_url)?_c('img',{staticClass:"order-products__image",attrs:{"src":row.item.image_url}}):_c('svg-icon',{staticClass:"order-products__image",attrs:{"name":"no-image"}}),_c('product-meta',{attrs:{"product":Object.assign({}, {id: row.id}, row.item)}})],1)]}},{key:"name",fn:function(ref){
var row = ref.row;
return [(row.item && row.item.name)?_c('tooltip',{attrs:{"no-arrow":""},scopedSlots:_vm._u([{key:"popover",fn:function(){return [_vm._v(" "+_vm._s(row.item.name)+" ")]},proxy:true}],null,true)},[_c('span',{staticClass:"order-products__name"},[_vm._v(" "+_vm._s(row.item.name)+" ")])]):_vm._e()]}},{key:"price",fn:function(ref){
var price = ref.price;
return [(price)?[_vm._v(" "+_vm._s(_vm.formatPrice(price))+" руб. ")]:_vm._e()]}},{key:"product_count",fn:function(ref){
var product_count = ref.product_count;
return [_vm._v(" "+_vm._s(product_count)+" шт ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }