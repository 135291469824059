<template>
  <div class="order-list">
    <div class="order-list__heading">
      <div class="order-list__heading-info">
        <span
          v-if="orders.length"
          class="order-list__count"
        >
          Найдено заказов: <strong>{{ ordersCount }}</strong>
        </span>
        <checkbox
          v-if="orders.length"
          v-model="unfoldAll"
          rounded
          class="order-list__checkbox"
        >
          Раскрыть все заказы
        </checkbox>
      </div>

      <btn
        primary
        icon="download"
        class="order-list__export"
        :is-load="downloadPending"
        @click="$emit('download-click')"
      >
        Выгрузить в Excel
      </btn>
    </div>

    <grid
      auto
      unfoldable
      :selectable="!!orders.length"
      :cells="computedOrders"
      :columns="columns"
    >
      <template #id="{ row }">
        <unfold-button
          :active="row.isUnfold"
          @click="$set(row, 'isUnfold', !row.isUnfold)"
        >
          <img
            v-if="row.order_items.length && row.order_items[0].item && row.order_items[0].item.image_url"
            :src="row.order_items[0].item.image_url"
            alt="order image"
            class="order-list__image"
          >

          <svg-icon
            v-else
            name="no-image"
            class="order-list__image"
          />

          <product-meta
            is-order
            :order="{ ...row, id: row.order_id }"
            path-name="order"
            :path-params="{id: row.id}"
            class="order-list__meta"
          />
        </unfold-button>
      </template>

      <template #unfold="{ row }">
        <grid
          auto
          bordered
          :cells="row.order_items"
          :columns="productColumns"
          class="order-list__product-grid"
        >
          <template #product_id="{ row: product }">
            <div class="order-list__product-meta">
              <img
                v-if="product.item && product.item.image_url"
                :src="product.item.image_url"
                alt="order image"
                class="order-list__image"
              >

              <svg-icon
                v-else
                name="no-image"
                class="order-list__image"
              />

              <product-meta
                :product="{ ...product, barcode: product.item && product.item.barcode }"
                class="order-list__meta"
              />
            </div>
          </template>

          <template #price="{ price }">
            {{ formatPrice(price) }} <br> руб
          </template>

          <template #cost="{ cost }">
            {{ formatPrice(cost) }} <br> руб
          </template>

          <template #description="{ description }">
            {{ description ? description.name : '' }}
          </template>
        </grid>
      </template>

      <template #shop="{ shop, row }">
        <badge
          :status="row.order_status"
          :status-list="computedStatuses"
          class="order-list__badge"
        />
        <marketplace
          full
          :name="row.marketplace.code"
          :shop="shop.name"
          class="order-list__marketplace"
        />
      </template>

      <template #pay_amount="{ pay_amount }">
        <strong>{{ formatPrice(pay_amount) }} руб</strong>
      </template>

      <template #discount_amount="{ discount_amount }">
        {{ formatPrice(discount_amount) }} руб
      </template>

      <template #logistics_type="{ logistics_type, row }">
        <template v-if="row.shipment_date">
          До отправки: <br>
          <span class="order-list__delivery">
            <strong>{{ getShipmentDiff(row.shipment_date) }}</strong>
            <br>
          </span>
        </template>
        <p class="order-list__delivery-method">
          {{ logistics_type }}
        </p>
      </template>
    </grid>

    <no-data
      v-if="!orders.length"
      :action="shops.length ? null : 'Добавить магазин'"
      class="order-list__no-data"
      @click="goToShopAdd"
    >
      <template #icon>
        <svg-icon
          name="empty-table"
          class="order-list__no-data-icon"
        />
      </template>
      Информация в таблице отсутствует
    </no-data>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Checkbox from '@/components/Interface/Checkbox.vue'
import Btn from '@/components/Interface/Button.vue'
import Grid from '@/components/Interface/Grid.vue'
import Badge from '@/components/Common/Badge.vue'
import Marketplace from '@/components/Common/Marketplace.vue'
import ProductMeta from '@/components/Common/ProductMeta.vue'
import UnfoldButton from '@/components/Interface/UnfoldButton.vue'
import NoData from '@/components/Interface/NoData.vue'
import formatPrice from '@/utils/price-formatter'
import { getDiff } from '@/utils/date-formatter'
import columns, { productColumns } from '@/constants/orders'

const { mapState } = createNamespacedHelpers('orders')
const { mapState: mapShopsState } = createNamespacedHelpers('marketplaces')

export default {
  components: {
    Checkbox,
    Btn,
    Grid,
    Badge,
    Marketplace,
    ProductMeta,
    UnfoldButton,
    NoData
  },

  props: {
    orders: {
      type: Array,
      default: () => []
    },
    downloadPending: Boolean
  },

  data () {
    return {
      columns,
      productColumns,
      unfoldAll: false
    }
  },

  computed: {
    ...mapState(['ordersCount', 'orderStatuses']),
    ...mapShopsState(['shops']),

    computedOrders () {
      return this.unfoldAll ? this.orders.map(o => ({ ...o, isUnfold: true })) : this.orders
    },

    computedStatuses () {
      return this.orderStatuses.reduce((list, curr) => {
        list[curr.value] = curr.name
        return list
      }, {})
    }
  },

  methods: {
    formatPrice,

    getShipmentDiff (shipDate) {
      const diff = getDiff(new Date(), shipDate)
      const days = Math.floor(diff.asDays())
      return `${days} дн ${diff.hours()} ч ${diff.minutes()} мин`
    },

    goToShopAdd () {
      this.$router.push({ name: 'marketplaces', query: { add: true } })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .order-list
    &__heading
      display flex
      align-items center
      justify-content space-between
      margin-bottom 33px

    &__heading-info
      display flex
      align-items center

    &__checkbox
      margin-left 20px

    &__count
      font-size 14px

    &__export
      flex-basis 202px
      font-weight 600

    &__image
      width 70px
      height 70px
      border 1px solid #e5e5e9
      border-radius 4px

    &__meta
      margin-left 8px

    &__product-grid
      padding 25px 0

    &__badge
      margin-bottom 16px

    &__marketplace
      padding 0

    &__delivery
      padding 2px 8px
      margin-bottom 4px
      border-radius 20px
      background-color #fcebeb

    &__product-meta
      display flex

    &__no-data-icon
      width 60px
      height 50px
</style>
